const Persons = (props) => {
    if (!props.persons || !Array.isArray(props.persons)) {
        console.log("Empty array")
        return <div> </div>
    }
    return(
        props.persons.map(person => <div key={person.id}>
            {person.name + " " + person.number + " "}
            <button onClick={() => props.deleteFn(person.id, person.name)}>delete</button></div>)
    )
}

export default Persons
